<div *transloco="let t; read: 'tree'">
    <fuse-alert
        *ngIf="itemToBeMoved()"
        [appearance]="'soft'"
        [type]="'basic'"
        [showIcon]="false"
        class="text-center block my-auto p-2">
        <div class="flex flex-col gap-2 flex-1 w-full">
            <div class="flex flex-row gap-2 justify-center">
                <mat-icon svgIcon="feather:alert-triangle"></mat-icon>
                <h6 class="my-auto"> {{ t('repositioningModeTitle') }} </h6>
            </div>
            <span>
                <b> {{ t('itemToMove') }} </b> {{ itemToBeMoved().title }}
            </span>
            <span> {{ t('repositioningModeDescription') }} </span>
            <div class="flex flex-row gap-2 justify-center">
                <button mat-button (click)="cancel()"> {{ t('cancel') }} </button>
                <button mat-raised-button color="primary" (click)="move()">
                    {{ t('move') }}
                </button>
            </div>
        </div>
    </fuse-alert>
    <div [ngClass]="itemToBeMoved() ? 'repositioningMode' : ''">
        <custom-tree
            class="orgSysArea-tree"
            [data]="treeData"
            [selectNodeId]="selectedNodeId"
            [treeConfig]="treeConfig()"
            [transformFunction]="transformFunction()"
            (treeNodeSelectionChange)="treeNodeSelected($event)"
            (menuItemClick)="menuItemClicked($event)">
            <ng-template #expandableNode let-node>
                @if (node.meta.status) {
                    <span
                        class="status"
                        [ngClass]="{
                            gray: node.meta.status === 'INACTIVE',
                            green: node.meta.status === 'ACTIVE',
                            orange: node.meta.status === 'DEVELOPMENT',
                        }"></span>
                }
                <span class="node-label" [innerHTML]="node.title"></span>
            </ng-template>
            <ng-template #leafNode let-node>
                @if (node.meta.status) {
                    <span
                        class="status"
                        [ngClass]="{
                            gray: node.meta.status === 'INACTIVE',
                            green: node.meta.status === 'ACTIVE',
                            orange: node.meta.status === 'DEVELOPMENT',
                        }"></span>
                }
                <span class="node-label" [innerHTML]="node.title"></span>
            </ng-template>
        </custom-tree>
    </div>
</div>
