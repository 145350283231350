import { Injector, WritableSignal, effect, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { DialogBuilderService } from '../dialogBuilder/dialog-builder.service';

export enum TaskConvertFlowType {
    StandardToSubprocess,
}

export interface IWorkflowElement {
    execute(...args: any[]): Promise<any>;
}

export class WorkflowTask implements IWorkflowElement {
    constructor(
        protected inject: Injector,
        protected _destroy: Subject<void>
    ) {}

    /**
     * This function should be overridden in derived classes.
     * It is the entrypoint of the workflow task. It should
     * return a promise that resolves with the result of the task.
     * The result can be one of the following:
     * - A string, which is the code of the next task in the workflow.
     *   The code is used to find the next task in the workflow.
     * - A boolean, which is true if the task is finished successfully,
     *   or false if the task is finished with an error.
     * - null or undefined, which is the same as returning true.
     * @param args any[] The arguments passed to the task.
     * @returns Promise<any> A promise that resolves with the result of the task.
     */
    execute(...args: any[]): Promise<any> {
        throw Error('Execute is not implemented');
    }
}

export class DialogWorkflowTask extends WorkflowTask {
    protected dialog: MatDialogRef<any, any>;
    protected dialogService: DialogBuilderService;
    protected _translocoService: TranslocoService;
    protected executeActionSignal: WritableSignal<string> = signal(null);
    protected response: string;

    constructor(inject: Injector, _destroy: Subject<void>) {
        super(inject, _destroy);

        this.dialogService = this.inject.get(DialogBuilderService);
        this._translocoService = this.inject.get(TranslocoService);

        effect(
            () => {
                const code = this.executeActionSignal();
                if (code) {
                    this.response = code;
                    this.dialog?.close();
                }
            },
            {
                injector: this.inject,
                allowSignalWrites: true,
            }
        );
    }
}

export interface BinaryWorkflowDecisionConfig {
    condition: (...args: any[]) => boolean | Promise<boolean>;
    task: IWorkflowElement;
    trueTask: IWorkflowElement | IWorkflowElement[];
    falseTask?: IWorkflowElement | IWorkflowElement[];
}

export class BinaryWorkflowDecision implements IWorkflowElement {
    protected condition: (...args: any[]) => boolean | Promise<boolean>;
    protected task: IWorkflowElement;
    protected trueTask: IWorkflowElement | IWorkflowElement[];
    protected falseTask?: IWorkflowElement | IWorkflowElement[];

    constructor(
        protected inject: Injector,
        protected _destroy: Subject<void>,
        config: BinaryWorkflowDecisionConfig
    ) {
        this.condition = config.condition;
        this.task = config.task;
        this.trueTask = config.trueTask;
        this.falseTask = config.falseTask;
    }

    /**
     * Executes the workflow decision logic by first executing the initial task.
     * Based on the response of the initial task, evaluates a condition to determine
     * the subsequent path to execute.
     *
     * If the condition resolves to true, executes the tasks in the true path.
     * If the condition resolves to false and a false path is defined, executes
     * the tasks in the false path.
     *
     * The function returns the final response of the executed path, which can be
     * a string representing the outcome or 'cancel' if the execution was interrupted.
     *
     * @param args - The arguments to be passed to the task execution methods.
     * @returns A promise that resolves to a string representing the outcome of the execution.
     */
    async execute(...args: any[]): Promise<string> {
        const response = await this.task.execute(...args);
        const conditionResult = await this.condition(response);

        if (conditionResult) {
            if (Array.isArray(this.trueTask)) {
                let lastResponse: any;
                for (const task of this.trueTask) {
                    lastResponse = await task.execute(...args);
                    if (lastResponse === 'cancel') {
                        break;
                    }
                }
                return lastResponse;
            }
            return await this.trueTask.execute(...args);
        } else if (response === 'cancel') {
            return 'cancel';
        } else if (this.falseTask) {
            if (Array.isArray(this.falseTask)) {
                let lastResponse: any;
                for (const task of this.falseTask) {
                    lastResponse = await task.execute(...args);
                    if (lastResponse === 'cancel') {
                        break;
                    }
                }
                return lastResponse;
            }
            return await this.falseTask.execute(...args);
        }
    }
}

export interface MultiOptionWorkflowDecisionConfig {
    task: IWorkflowElement;
    decisionMap: Map<string, IWorkflowElement | IWorkflowElement[]>;
}

export class MultiOptionWorkflowDecision implements IWorkflowElement {
    protected task: IWorkflowElement;
    protected decisionMap: Map<string, IWorkflowElement | IWorkflowElement[]>;

    constructor(
        protected inject: Injector,
        protected _destroy: Subject<void>,
        config: MultiOptionWorkflowDecisionConfig
    ) {
        this.task = config.task;
        this.decisionMap = config.decisionMap;
    }

    /**
     * Executes the task and, if a decision map is defined, executes the tasks in the map
     * for the response of the task. If the response is not found in the map, the response
     * is returned directly.
     *
     * @param args The arguments to be passed to the task.
     * @returns The response of the task or the last response of the tasks in the map, if
     * the response is found in the map.
     */
    async execute(...args: any[]): Promise<string> {
        const response = await this.task.execute(...args);

        if (this.decisionMap) {
            const taskForResponse = this.decisionMap.get(response);
            if (taskForResponse) {
                if (Array.isArray(taskForResponse)) {
                    let lastResponse: any;
                    for (const task of taskForResponse) {
                        lastResponse = await task.execute(...args);
                        if (lastResponse === 'cancel') {
                            break;
                        }
                    }
                    return lastResponse;
                } else {
                    return await taskForResponse.execute(...args);
                }
            }
        }

        return response;
    }
}

export class Workflow<T extends IWorkflowElement> {
    private tasks: T[] = [];
    private lastTask: T;

    /**
     * Adds a task to the workflow. The task will be executed sequentially with other
     * tasks added to the workflow. If any task in the workflow returns a 'cancel'
     * response, the execution halts and, if defined, the last task is executed.
     * @param task The task to be added to the workflow.
     */
    addTask(task: T): void {
        this.tasks.push(task);
    }

    /**
     * Sets the specified task as the last task to be executed in the workflow.
     * This task will be executed if any task in the workflow returns a 'cancel' response
     * or after all other tasks have been executed.
     *
     * @param task The task to be set as the last task in the workflow.
     */
    addLastTask(task: T): void {
        this.lastTask = task;
    }

    /**
     * Executes all tasks in the workflow sequentially, passing the provided arguments
     * to each task's execute method. If any task returns a 'cancel' response, the
     * execution halts and, if defined, the last task is executed. After all tasks
     * are executed, if a last task is defined, it is executed.
     *
     * @param args The arguments to be passed to each task's execute method.
     * @returns A promise that resolves when all tasks have been executed.
     */
    async execute(...args: any[]): Promise<void> {
        let lastResponse: any;
        for (const task of this.tasks) {
            lastResponse = await task.execute(...args);
            if (lastResponse === 'cancel') {
                if (this.lastTask) {
                    await this.lastTask.execute(...args);
                }
                return;
            }
        }
        if (this.lastTask) {
            await this.lastTask.execute(...args);
        }
    }
}
