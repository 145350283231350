import {
    Component,
    InputSignal,
    OnInit,
    Signal,
    computed,
    effect,
    input,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogModule } from '@angular/material/dialog';
import { ContextMenuItem } from '@em4cloud/my-cdk';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TRANSLOCO_SCOPE, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { LibOrgSysAreaTreeComponent } from '../lib-org-sys-area-tree/lib-org-sys-area.component';
import {
    OrgSysAreaFlatTreeNode,
    OrgSysAreaTreeNodeType,
} from '../lib-org-sys-area-tree/lib-org-sys-area.types';
import { OrgSysAreaWorkflowService } from '../org-sys-area-workflow.service';
import { OrgSysAreaService } from '../org-sys-area.service';

@Component({
    selector: 'org-sys-area-tree',
    standalone: true,
    imports: [LibOrgSysAreaTreeComponent, FuseAlertComponent, TranslocoModule, MatDialogModule],
    templateUrl: './org-sys-area-tree.component.html',
    styleUrl: './org-sys-area-tree.component.scss',
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'bpOrganisation' }],
})
export class OrgSysAreaTreeComponent<T> extends DialogBuilderComponent<any> implements OnInit {
    orgSysAreaService: InputSignal<OrgSysAreaService<T, any>> = input();
    type: InputSignal<'org' | 'sys' | 'area'> = input();
    filterToActive: InputSignal<boolean> = input(false);

    translocoContent: Signal<any>;

    organisationData: Signal<T[]> = computed(() => {
        if (this.orgSysAreaService()) {
            return this.orgSysAreaService().filteredItems();
        } else return [];
    });
    selectedOrgId = computed(() => {
        return this.orgSysAreaService() && this.orgSysAreaService().selectedItem()
            ? this.orgSysAreaService().selectedItem()['id']
            : null;
    });

    contextMenuItems: Signal<ContextMenuItem<any>[]> = computed(() =>
        this.orgSysAreaService().contextMenuItems()
    );

    isDialog = signal(false);
    editMode = signal(true);
    allowedSelectionList = computed(() => this.orgSysAreaService().allowedSelectionList);

    activeOrgKeys = computed(() => {
        return this.orgSysAreaService().activeKeys();
    });

    constructor(
        private _translocoService: TranslocoService,
        private workflowService: OrgSysAreaWorkflowService
    ) {
        super();
        this.translocoContent = toSignal(
            this._translocoService.selectTranslation('bpOrganisation')
        );

        effect(() => {
            const filterToActive = this.filterToActive();
            this.orgSysAreaService().initializeData(filterToActive);
        });
    }

    ngOnInit(): void {
        if (!this.componentData) {
            this.componentData = {
                model: null,
            };
        }
        super.ngOnInit();

        // when this component is opened as a dialog component with preselection
        if (this.componentModel) {
            this.orgSysAreaService().selectedItem = this.componentModel;
        }
        if (this.componentData.type) {
            this.isDialog.set(true);
            this.editMode.set(false);
        }
    }

    menuItemClicked($event): void {
        const id: string = $event.menuItem.id;
        if (id === 'createSiblingItem') {
            this.orgSysAreaService().selectItemById($event.node.id);
            this.workflowService.startWorkflow(this.type(), 'sameLevel', this.orgSysAreaService());
            return;
        } else if (id === 'createChildItem') {
            this.orgSysAreaService().selectItemById($event.node.id);
            this.workflowService.startWorkflow(this.type(), 'asChild', this.orgSysAreaService());
            return;
        }
        this.orgSysAreaService().menuItemClicked($event, this.type());
    }

    treeNodeSelected($event: OrgSysAreaFlatTreeNode): void {
        this.orgSysAreaService().selectItemById($event.id);
    }

    treeChanged($event: T[]): void {
        this.orgSysAreaService().updateItems($event);
    }

    getNodeIcons(nodeType: OrgSysAreaTreeNodeType | string): string {
        return this.orgSysAreaService().getNodeIcons(nodeType);
    }
}
