import { Injectable, Injector } from '@angular/core';
import {
    BinaryWorkflowDecision,
    DialogWorkflowTask,
    Workflow,
    WorkflowTask,
} from 'app/core/workflow/workflow';
import { Subject } from 'rxjs';
import { OrgSysAreaTreeService } from './lib-org-sys-area-tree/lib-org-sys-area.service';
import { OrgSysAreaTreeNode } from './lib-org-sys-area-tree/lib-org-sys-area.types';
import { OrgSysAreaService } from './org-sys-area.service';
import { AlertService } from 'app/core/alert/alert.service';

@Injectable({
    providedIn: 'root',
})
export class OrgSysAreaWorkflowService {
    private workflow: Workflow<any> = new Workflow();
    private _destroy: Subject<void>;
    private alertService: AlertService;

    selectedType: string | undefined;

    constructor(private inject: Injector) {
        this.defineWorkflow();
        this.alertService = this.inject.get(AlertService);
    }

    setDestroy(_destroy: Subject<void>): void {
        this._destroy = _destroy;
    }

    startWorkflow(
        type: 'org' | 'sys' | 'area',
        pos: 'sameLevel' | 'asChild',
        service: OrgSysAreaService<any, any>
    ): void {
        this.workflow.execute(type, pos, service, this);
    }

    private defineWorkflow(): void {
        /**
         * This workflow is navigating the user through
         * creating a new item.
         *
         * Steps:
         *  1. Asks the user where to put the new item
         *      1.1 Same level
         *      1.2 As Child
         *  2. If same level, then set selectedItem properly
         *  3. Asks the user what type of item should be created
         *      3.1 Show the available options
         *  4. Open the editor for the selected type
         *  5. Create the new item
         */
        const selectNewItemPositionWorkflowTask = new SelectNewItemPositionWorkflowTask(
            this.inject,
            this._destroy
        );
        const setSelectedItemToMatchSameLevelWorkflowTask =
            new SetSelectedItemToMatchSameLevelWorkflowTask(this.inject, this._destroy);
        const selectNewItemTypeWorkflowTask = new SelectNewItemTypeWorkflowTask(
            this.inject,
            this._destroy
        );
        const createNewItemWorkflowTask = new CreateNewItemWorkflowTask(this.inject, this._destroy);
        const finishNewItemWorkflowTask = new FinishNewItemWorkflowTask(this.inject, this._destroy);

        const workflowDecision = new BinaryWorkflowDecision(this.inject, this._destroy, {
            task: selectNewItemPositionWorkflowTask,
            condition: selection => {
                return selection === 'sameLevel';
            },
            trueTask: setSelectedItemToMatchSameLevelWorkflowTask,
        });

        this.workflow.addTask(workflowDecision);
        // this.workflow.addTask(selectNewItemTypeWorkflowTask);
        this.workflow.addTask(createNewItemWorkflowTask);
        this.workflow.addLastTask(finishNewItemWorkflowTask);
    }

    public showError(message: string): void {
        this.alertService.errorAlert('bpOrganisation', message);
    }
}

export class SelectNewItemPositionWorkflowTask extends DialogWorkflowTask {
    constructor(inject: Injector, _destroy: Subject<void>) {
        super(inject, _destroy);
    }

    override async execute(
        type: 'org' | 'sys' | 'area',
        pos: 'sameLevel' | 'asChild',
        service: OrgSysAreaService<any, any>,
        workflowService: OrgSysAreaWorkflowService
    ): Promise<any> {
        const selectedNode = service.selectedItem();
        let response = pos;
        if (!selectedNode && type === 'org') {
            workflowService.showError('selectOrganisationError');
            return 'cancel';
        }
        this.executeActionSignal.set(response);
        return response;
    }
}

export class SetSelectedItemToMatchSameLevelWorkflowTask extends WorkflowTask {
    private orgSysAreaTreeService: OrgSysAreaTreeService<any>;

    constructor(inject: Injector, _destroy: Subject<void>) {
        super(inject, _destroy);

        this.orgSysAreaTreeService = this.inject.get(OrgSysAreaTreeService<any>);
    }

    private findParentNode(
        tree: OrgSysAreaTreeNode[],
        selectedNodeId: string
    ): OrgSysAreaTreeNode | null {
        for (let node of tree) {
            if (node.children && node.children.length > 0) {
                // Check if the current node's children contain the selected node
                const foundChild = node.children.find(child => child.id === selectedNodeId);
                if (foundChild) {
                    return node; // Current node is the parent of the selected node
                }

                // Recursively search in the children
                const parent = this.findParentNode(node.children, selectedNodeId);
                if (parent) {
                    return parent; // Parent found in the deeper level
                }
            }
        }

        return null; // Parent not found
    }

    override async execute(
        type: 'org' | 'sys' | 'area',
        pos: 'sameLevel' | 'asChild',
        service: OrgSysAreaService<any, any>,
        workflowService: OrgSysAreaWorkflowService
    ): Promise<any> {
        // Set root folder
        const selected = service.selectedItem();
        if (!selected && type !== 'org') {
            return;
        }
        if (type === 'org') {
            const topLevelNodes = this.orgSysAreaTreeService
                .treeNodes()
                .filter(org => org.meta['status'] === 'DEVELOPMENT');
            // No selection and more than one node in development
            if (!selected && topLevelNodes.length > 1) {
                workflowService.showError('selectOrganisationError');
                return 'cancel';
            }
            // No selection and only one node in development
            else if (!selected && topLevelNodes.length === 1) {
                service.selectItemById(topLevelNodes[0].id);
            }
            // There is a selection and it's a top level node in development status
            else if (selected && selected['id'] === topLevelNodes.some(n => n.id === selected.id)) {
                const topLevelNode = topLevelNodes.find(n => n.id === selected.id);
                service.setSelectedItem(topLevelNode as any);
            }
            // There is a selection but it's not a top level node
            else {
                const parent = this.findParentNode(
                    this.orgSysAreaTreeService.treeNodes(),
                    selected.id
                );

                if (parent) {
                    service.selectItemById(parent.id);
                } else {
                    workflowService.showError('selectOrganisationError');
                    return 'cancel';
                }
            }
        } else {
            let treeNodes = [];
            this.orgSysAreaTreeService.treeNodes().forEach(treeNode => {
                treeNodes.push(...treeNode.children);
            });
            const parent = this.findParentNode(treeNodes, selected.id);

            service.setSelectedItem(parent as any);
        }
    }
}

export class SelectNewItemTypeWorkflowTask extends DialogWorkflowTask {
    constructor(inject: Injector, _destroy: Subject<void>) {
        super(inject, _destroy);
    }

    override async execute(
        type: 'org' | 'sys' | 'area',
        pos: 'sameLevel' | 'asChild',
        service: OrgSysAreaService<any, any>,
        workflowService: OrgSysAreaWorkflowService
    ): Promise<any> {
        this.dialog = this.dialogService.openDialog({
            descriptor: {
                header: {
                    title: this._translocoService.translate(
                        'bpOrganisation.workflow.itemTypeTitle'
                    ),
                    showCloseButton: true,
                },
                content: {
                    description: this._translocoService.translate(
                        'bpOrganisation.workflow.itemTypeDescription'
                    ),
                },
                actions: {
                    dialogActions: service.createNewItemActions(),
                },
                executeActionSignal: this.executeActionSignal,
                dialogSize: 'l',
            },
        });

        return await this.dialog
            .afterClosed()
            .toPromise()
            .then(() => {
                workflowService.selectedType = this.response;
                this.executeActionSignal.set(null);
                this.response = null;

                return workflowService.selectedType ?? 'cancel';
            });
    }
}

export class CreateNewItemWorkflowTask extends DialogWorkflowTask {
    constructor(inject: Injector, _destroy: Subject<void>) {
        super(inject, _destroy);
    }

    override async execute(
        type: 'org' | 'sys' | 'area',
        pos: 'sameLevel' | 'asChild',
        service: OrgSysAreaService<any, any>,
        workflowService: OrgSysAreaWorkflowService
    ): Promise<any> {
        return await service.newItem(service.selectedItem(), workflowService.selectedType);
    }
}

export class FinishNewItemWorkflowTask extends WorkflowTask {
    constructor(inject: Injector, _destroy: Subject<void>) {
        super(inject, _destroy);
    }

    override async execute(
        type: 'org' | 'sys' | 'area',
        pos: 'sameLevel' | 'asChild',
        service: OrgSysAreaService<any, any>,
        workflowService: OrgSysAreaWorkflowService
    ): Promise<any> {
        workflowService.selectedType = undefined;
    }
}
