<div *transloco="let t; read: 'bpOrganisation'" [class.dialogContainer]="this.isDialog()">
    @if (!organisationData() || organisationData().length == 0) {
        <fuse-alert
            [appearance]="'soft'"
            [type]="'basic'"
            [showIcon]="false"
            class="text-center block my-auto p-2 w-full">
            {{ t('noData') }}
        </fuse-alert>
    } @else {
        <lib-org-sys-area-tree
            [nodeIcons]="getNodeIcons.bind(this)"
            [contextMenuItems]="contextMenuItems()"
            [editMode]="editMode()"
            [activeOrgTypeKeys]="activeOrgKeys()"
            [selectedNodeId]="selectedOrgId()"
            [organisationData]="organisationData()"
            [translocoContent]="translocoContent()"
            [allowedSelectionList]="allowedSelectionList()"
            (treeNodeSelection)="treeNodeSelected($event)"
            (menuItemClick)="menuItemClicked($event)"
            (treeChanged)="treeChanged($event)">
        </lib-org-sys-area-tree>
    }
</div>
