import {
    Component,
    EventEmitter,
    Input,
    InputSignal,
    Output,
    Signal,
    WritableSignal,
    computed,
    effect,
    input,
    signal,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContextMenuItem, ContextMenuItemClickData } from '@em4cloud/my-cdk';
import { TreeConfig, TreeViewComponent } from '@em4cloud/tree-view';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { OrgSysAreaTreeService } from './lib-org-sys-area.service';
import {
    OrgSysAreaFlatTreeNode,
    OrgSysAreaTreeNode,
    OrgSysAreaTreeNodeType,
} from './lib-org-sys-area.types';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { RoleService } from 'app/core/role-management/role-management.service';

@Component({
    selector: 'lib-org-sys-area-tree',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,
        MatIconModule,
        MatButtonModule,
        TreeViewComponent,
        FuseAlertComponent,
    ],
    templateUrl: './lib-org-sys-area.component.html',
    styleUrl: './lib-org-sys-area.component.scss',
})
export class LibOrgSysAreaTreeComponent<T> {
    @Output() treeNodeSelection = new EventEmitter<OrgSysAreaFlatTreeNode>();
    @Output() menuItemClick = new EventEmitter<ContextMenuItemClickData<OrgSysAreaFlatTreeNode>>();
    @Output() treeChanged = new EventEmitter<T[]>();

    organisationData = input<T[]>([]);
    treeData = computed<OrgSysAreaTreeNode[]>(this._orgTreeService.treeNodes);
    allowedSelectionList = input<string[]>([]);
    activeOrgTypeKeys = input<OrgSysAreaTreeNodeType[]>([]);
    selectedNodeId = input<string>();

    translocoContent = input<any>({});
    editMode = input(false);
    @Input() nodeIcons: (nodeType: OrgSysAreaTreeNodeType | string) => string;

    transformFunction = computed(() => {
        return (node: OrgSysAreaTreeNode, level: number): Partial<OrgSysAreaFlatTreeNode> => {
            return {
                id: node.id,
                icon: this.nodeIcons(node.type as any),
                checkbox: this.getCheckbox(node),
                type: node.type,
                class: this.getClass(node),
                title: node.title,
                status: node.meta['status'],
            };
        };
    });

    getCheckbox(node: OrgSysAreaTreeNode): boolean {
        if (typeof node.meta.checkbox === 'boolean' && !node.meta.checkbox) {
            return false;
        }

        if (!this.itemToBeMoved()) {
            return this.allowedSelectionList().length
                ? this.allowedSelectionList().includes(node.type?.toLowerCase())
                : true;
        }

        if (node.id === this.itemToBeMoved().id) {
            return false;
        }
        if (this.findDeep(node.id, this.itemToBeMoved2().children)) {
            return false;
        }

        const root = this._orgTreeService.findRootParentNode(node.id);

        if (root && root['versionId'] && root['status'] && root['status'] !== 'DEVELOPMENT') {
            return false;
        } else if (
            !root &&
            node['meta'] &&
            node['meta']['status'] &&
            node['meta']['status'] !== 'DEVELOPMENT'
        ) {
            return false;
        }

        return true;
    }

    getClass(node: OrgSysAreaTreeNode): string {
        if (!this.itemToBeMoved()) {
            return '';
        }

        if (node.id === this.itemToBeMoved().id) {
            return 'selected selection-first';
        }
        if (this.findDeep(node.id, this.itemToBeMoved2().children)) {
            return 'selected selection-child';
        }
        return '';
    }

    contextMenuItems: InputSignal<ContextMenuItem<any>[]> = input();

    moveItem: ContextMenuItem<any> = {
        id: 'move',
        isVisible: this.checkIfVisible.bind(this),
        title: 'Move',
    };

    itemToBeMoved: WritableSignal<OrgSysAreaFlatTreeNode> = signal(null);
    itemToBeMoved2: WritableSignal<OrgSysAreaTreeNode> = signal(null);
    newParentItem: WritableSignal<OrgSysAreaFlatTreeNode> = signal(null);

    treeConfig: Signal<TreeConfig> = computed(() => {
        return {
            contextMenuConfig: {
                enableContextMenu: this.editMode(),
                contextMenuItems: [...this.contextMenuItems(), this.moveItem],
            },
        };
    });

    private checkIfVisible(node: any): boolean {
        if (node.level > 0) {
            return (
                this._orgTreeService.checkIfContextMenuItemVisible(node) &&
                this.roleService.hasAnyRole(['ADM', 'CON'])
            );
        }
        return false;
    }

    private dialogRef: MatDialogRef<any, any>;

    private executeActionSignal: WritableSignal<string> = signal(null);

    constructor(
        private _orgTreeService: OrgSysAreaTreeService<T>,
        private dialogService: DialogBuilderService,
        private roleService: RoleService
    ) {
        effect(
            () => {
                this._orgTreeService.orgData.set(this.organisationData());
            },
            {
                allowSignalWrites: true,
            }
        );

        effect(() => {
            const code = this.executeActionSignal();
        });
    }

    treeNodeSelected(node: OrgSysAreaFlatTreeNode) {
        if (this.itemToBeMoved()) {
            this.newParentItem.set(node);
            return;
        }
        this.treeNodeSelection.emit(node);
    }

    menuItemClicked($event: ContextMenuItemClickData<OrgSysAreaFlatTreeNode>) {
        if ($event.menuItem.id === this.moveItem.id) {
            this.openMoveItemDialog($event.node);
            return;
        }
        this.menuItemClick.emit($event);
    }

    private openMoveItemDialog(node: OrgSysAreaFlatTreeNode): void {
        this.itemToBeMoved.set(node);
        const node2 = this.findDeep(node.id, this.treeData());
        this.itemToBeMoved2.set(node2);
    }

    findDeep(idToFind: string, objects: any[]): any | null {
        if (!objects || !objects.length) {
            return null;
        }
        for (let index = 0; index < objects.length; index++) {
            const element = objects[index];
            if (element['id'] === idToFind) {
                return element;
            }

            if (element['children'] && element['children'].length) {
                const result = this.findDeep(idToFind, element['children']);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    cancel(): void {
        this.itemToBeMoved.set(null);
        this.newParentItem.set(null);
    }

    move(): void {
        const orgDataInDevelopment = this.organisationData().filter(
            orgData => orgData['status'] === 'DEVELOPMENT'
        );
        if (this.itemToBeMoved().level > 0) {
            const itemToBeMovedId = this.itemToBeMoved().id.includes('_')
                ? this.itemToBeMoved().id.split('_')[1]
                : this.itemToBeMoved().id;

            const parent = this._orgTreeService.findDirectParentNode(
                orgDataInDevelopment,
                itemToBeMovedId
            ) as OrgSysAreaTreeNode;

            const itemToBeMoved = this.findDeep(itemToBeMovedId, orgDataInDevelopment);
            const index = parent.children.indexOf(itemToBeMoved);
            parent.children.splice(index);

            const newParentItemId = this.newParentItem().id.includes('_')
                ? this.newParentItem().id.split('_')[1]
                : this.newParentItem().id;
            const newParentItem = this.findDeep(
                newParentItemId,
                orgDataInDevelopment
            ) as OrgSysAreaTreeNode;

            newParentItem.children.push(itemToBeMoved);

            this.treeChanged.emit(orgDataInDevelopment);

            this.itemToBeMoved.set(null);
            this.newParentItem.set(null);
        }
    }
}
